import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";

import "../../scss/main.scss";

export default function Blog() {
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | Dementia</title>
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<section>
						<div className="dementia-landing">
							<StaticImage 
								src="../../static/assets/marketing-site/images/circle-dementia.svg" 
								loading="eager"
								placeholder="blurred"
							/>
							<h3>Dementia care at home</h3>
						</div>
						<hr />
					</section>

					<section>
						<div className="dementia-descrip">
							<StaticImage 
								src="../../static/assets/marketing-site/images/dementia-image-01.png"
								loading="eager"
								placeholder="blurred"
							/>
							<div className="descrip">
								<h3>
									Dementia diagnosis does not mean the end of an active, happy
									and fulfilled life.
								</h3>
								<p className="primary dem">
									It’s normal to be worried when someone you love gets diagnosed
									with dementia. The fear of the unknown can be unsettling.
									Despite this, it’s vital to remain positive. Thanks to ongoing
									research, live-in care techniques and assistive technology, it
									is possible to live well with dementia, and remain at home.
								</p>
								<p className="primary dem">
									At Edyn we know first hand the power of having familiar
									surroundings. We hope, therefore, to lighten the burden and
									help you through the experience by sharing some useful
									information that will enable you to ‘invite’ dementia into the
									home, rather than it kicking you out.
								</p>
							</div>
						</div>
					</section>
					<section>
						<div className="dementia-infographic">
							<div className="dementia-intro">
								<div className="descrip-img">
									<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-icon-02.svg" />
								</div>
								<h1>What is dementia?</h1>
								<p className="secondary pro">
									Dementia is a syndrome that occurs when the brain is damaged by diseases.
								</p>
							</div>
							<div className="img-divider">
								<StaticImage placeholder="blurred"
									className="img-divider-2"
									src="../../static/assets/marketing-site/images/dementia-icon-01.svg"
									
								/>
							</div>
							<div className="dementia-main">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-image-07.png" className="background-pattern" objectFit="contain"/>
								<div className="dementia-info">
									<div className="dementia-img-people">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-image-a.png" />
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-image-b.png" />
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-image-c.png" />
									</div>

									<h3>
										Dementia can affect anyone, whatever their gender, ethnic
										group, class, education and there is no cure, although
										research is going on.
									</h3>
									<p className="secondary pro">
										There are an estimated 100 types of Dementia. When your
										loved one is diagnosed with dementia, the diagnosing
										clinician should be able to tell your loved one what type
										they have. This is useful to know since symptoms and
										patterns of progression vary from one to another, and it may
										help you and your loved one feel more prepared for what the
										future holds.
									</p>
								</div>
								<div className="dementia-section">
									<div className="dementia-dropdown">
										<div className="dementia-dropdown-heading">
											<p>Some prominent forms of dementia</p>
											<hr />
										</div>
										<div className="dementia-forms">
											<div className="dementia-form accordion">
												<span className="accordion-link">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
														alt=""
														objectFit='contain'
													/>
													<h3>Alzheimer’s disease</h3>
												</span>
												<div
													className="dementia-form-stats accordion-items-container dementia"
													// data-collapsed="true"
												>
													<ul>
														<li>
															Is the main form of dementia, accounting for
															around 60% - 80% of all dementia diagnosis in the
															UK
														</li>
														<li>
															Usually affects a part of the brain called the
															hippocampus which relates to memory and emotion
														</li>
														<li>
															First sight of Alzheimer's disease is usually
															problems with memory, such as forgetting a
															conversation or the names of places or people.
														</li>
														<li>
															As the symptoms of Alzheimer’s disease often
															progress slowly it is commonly diagnosed fairly
															late due to symptoms of old age overlapping.
														</li>
													</ul>
												</div>
											</div>

											<div className="dementia-form accordion">
												<Link to="#" className="accordion-link">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
														alt=""
														objectFit="contain"
													/>
													<h3>Vascular dementia</h3>
												</Link>
												<div
													className="dementia-form-stats accordion-items-container dementia"
													// data-collapsed="true"
												>
													<ul>
														<li>
															Is the main form of dementia, accounting for
															around 60% - 80% of all dementia diagnosis in the
															UK
														</li>
													</ul>
												</div>
											</div>
											<div className="dementia-form accordion">
												<Link to="#" className="accordion-link">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
														alt=""
														objectFit="contain"
													/>
													<h3>Frontotemporal dementia</h3>
												</Link>
												<div
													className="dementia-form-stats accordion-items-container dementia"
													// data-collapsed="true"
												>
													<ul>
														<li>
															Is the main form of dementia, accounting for
															around 60% - 80% of all dementia diagnosis in the
															UK
														</li>
													</ul>
												</div>
											</div>
											<div className="dementia-form accordion">
												<Link to="#" className="accordion-link">
													<StaticImage placeholder="blurred"
														src="../../static/assets/marketing-site/images/dropdown-arrow.svg"
														alt=""
														objectFit='contain'
													/>
													<h3>Dementia with lewy bodies</h3>
												</Link>
												<div
													className="dementia-form-stats accordion-items-container dementia"
													// data-collapsed="true"
												>
													<ul>
														<li>
															Is the main form of dementia, accounting for
															around 60% - 80% of all dementia diagnosis in the
															UK
														</li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/dementia-icon-05.svg" className="desktop-only" 
									/>
								</div>

								<div className="dementia-symptons">
									<div className="symptons-descriptions">
										<h1>Symptoms</h1>
										<br />
										<p className="secondary pro">
											There are many different types of dementia, the most
											common being Alzheimer’s disease. It is progressive,
											meaning that symptoms will eventually get worse. It starts
											as ‘mild cognitive impairment’ (MCI): forgetfulness or
											‘senior moments”. But as it progresses the symptoms vary
											depending on the type.
										</p>
									</div>
									<div className="symptoms-diagram">
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-icon-03.svg" objectFit='contain' className="desktop-only"/>
										<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-icon-03-mobile.svg" objectFit='contain' className="mobile-only"/>
										<div className="diagram">
											<div className="diagram-descrip">
												<p className="secondary pro">
													Each type of dementia may affect different parts of
													the brain and thus result in different symptoms,
													though the early symptoms of dementia may be mild or
													increase fairly rapidly depending on a person of type.
													Many tend to think of dementia as mainly memory loss,
													but there is quite often a range of early symptoms
													which can be key indicators.
												</p>
											</div>
											<hr className="green" />
											<div className="diagram-topics">
												<div className="diagram-topic-one">
													<p className="secondary heading">Memory loss:</p>
													<p className="secondary pro">
														example struggling to recall a familiar face or name
													</p>
												</div>
												<div className="diagram-topic-one">
													<p className="secondary heading">Confusion:</p>
													<p className="secondary pro">
														example misplacing personal items, like glasses and
														medication
													</p>
												</div>
												<div className="diagram-topic-one">
													<p className="secondary heading">
														Linguistic problems:
													</p>
													<p className="secondary pro">
														example pauses in sentences and difficult finding
														words
													</p>
												</div>
												<div className="diagram-topic-one">
													<p className="secondary heading">
														Increase movement:
													</p>
													<p className="secondary pro">
														an uptick in movement around the house
													</p>
												</div>
												<div className="diagram-topic-one">
													<p className="secondary heading">
														Changes in mood and behaviour:
													</p>
													<p className="secondary pro">
														example loss of interest in hobbies
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="dementia-f-c">
							<div className="f-c-content">
								<div className="f-c-image">
									<h1>Dementia facts and stats</h1>
								</div>
								<div className="f-c-stats">
									<div className="stats">
										<h3>850,000</h3>
										<p className="primary">
											people in the UK living with dementia
										</p>
										{/*   <hr>  */}
									</div>
									<div className="stats">
										<p className="primary custom">Dementia attracts &nbsp;	</p>
										<h3 className="custom">7.4%</h3>
										<p className="primary">
											of the research finding that goes into cancer
										</p>
										{/*   <hr>  */}
									</div>
									<div className="stats">
										<p className="primary">
											A person is diagnosed with dementia every
										</p>
										<h3>three minutes</h3>
										{/*   <hr>  */}
									</div>
									<div className="stats">
										<h3>Over 100</h3>
										<p className="primary">different types of dementia</p>
										{/*   <hr>  */}
									</div>
									<div className="stats">
										<h3>70%</h3>
										<p className="primary">
											of people in care homes have dementia
										</p>
										{/*   <hr>  */}
									</div>
									<div className="stats">
										<h3>Disproportionately</h3>
										<p className="primary">effects women</p>
										{/*   <hr>  */}
									</div>
									{/* <div className="stats">
										<p className="primary">A person is diagnosed with dementia every</p>
										<h3 >three minutes</h3>
									</div> */}
								</div>
							</div>
						</div>
					</section>

					<section>
						<div className="dementia-live-in">
							<div className="dli-heading">
								<h1>Dementia live-in care </h1>
								<br />
								<p className="primary">
									We understand that with patience, trust and genuine care, we
									can help those diagnosed with dementia maintain a high quality
									of life and live comfortably in their own home.
								</p>
							</div>
							<div className="dli-block">
								<h3 className="mobile-view">Ensuring your home is dementia friendly</h3>
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-image-03.jpg" />
								<div className="block-text">
									<h3 className="desktop-view">Ensuring your home is dementia friendly</h3>
									<p className="secondary pro">
										When considering live-in care it’s important to ensure that
										the home of the care recipient is safe and secure. Does it
										compensate for any difficulties they are experiencing? Does
										it keep them safe? Does it support their mobility? <br />
										<br />
										At Edyn as part of our approach we complete a home risk
										assessment for all of our clients. Our care managers ensure
										that your home is the best possible setting for care. Small
										changes can make big differences.
									</p>
									<div className="tip">
										<p className="secondary pro">Top dementia care tip:</p>
										<br />
										<p className="secondary pro grey">
											Covering reflective surfaces, laying plain and consistent
											flooring and removing bold patterns are all quick wins.
										</p>
									</div>
								</div>
							</div>
							<div className="dli-block left">
							<h3 className="mobile-view">Professional carers trained specifically for dementia:</h3>
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-image-04.jpg" />
								<div className="block-text">
									<h3 className="desktop-view">
										Professional carers trained specifically for dementia:
									</h3>
									<p className="secondary pro">
										Our professional care teams receive on-going training,
										mentoring and support based on the latest thinking and
										research that ensure they have the skills to deliver
										positive dementia care. This includes:
									</p>
									<div className="tip">
											<li>
												<StaticImage placeholder="blurred" class="icon-default" src="../../static/assets/marketing-site/images/circle-dementia-activities.svg" objectFit="contain"/>
												<span>Creative activities</span>
											</li>
											<li>
												<StaticImage placeholder="blurred" class="icon-default" src="../../static/assets/marketing-site/images/circle-dementia-activities.svg" objectFit="contain"/>
												<span>Monitoring behaviour and cognitive symptoms</span>
											</li>
											<li>
												<StaticImage placeholder="blurred" class="icon-default" src="../../static/assets/marketing-site/images/circle-dementia-activities.svg" objectFit="contain"/>
												<span>Active recall of preserving memories</span>
											</li>
											<li>
												<StaticImage placeholder="blurred" class="icon-default" src="../../static/assets/marketing-site/images/circle-dementia-activities.svg" objectFit="contain"/>
												<span>Sensory and dental care</span>
											</li>
											<li>
												<StaticImage placeholder="blurred" class="icon-default" src="../../static/assets/marketing-site/images/circle-dementia-activities.svg" objectFit="contain"/>
												<span>Maintaining exercise</span>
											</li>
									</div>
								</div>
							</div>
							
						</div>
					</section>

					<section>
						<div className="customer-say">
							<div className="customer-words">
								<p className="secondary">What our customers say:</p>
								<div className="customer-words-group">
									<StaticImage placeholder="blurred"
										src="../../static/assets/marketing-site/images/quotation-mark.svg"
										objectFit='contain'
										className="quotation-mark"
									/>
									<h3>
										What makes Edyn stand out from other agencies we have used
										for live-in care is the fact that they care - the human
										touch is there.”
									</h3>
								</div>
							</div>
							<div className="customer-images">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/dementia-quote-customers.png" />
								<div className="customer-names">
									<p className="secondary">
										Sarah Maya
										<br />
										<span>Mum</span>
									</p>
									<p className="secondary">
										Julie Maya
										<br />
										<span>Daughter</span>
									</p>
								</div>
							</div>
						</div>
					</section>

					<section className="live-in dementia">
						<h1 className="live-in-heading">
							Why choose live-in care with Edyn
						</h1>
						<div className="reasons">
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/proffesional-carer.png"
									alt="proffesional carers"
								/>
								<h3>First class professional carers</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/support-teams.png"
									alt="Support Teams"
								/>
								<h3>Support teams to manage your care.</h3>
								<p className="primary">
									Our dedicated care specialists will handle the progression of
									your care.
								</p>
							</div>
							<div className="reason">
								<StaticImage placeholder="blurred"
									className="img-live-in"
									src="../../static/assets/marketing-site/images/technology.png"
									alt="Technology"
								/>
								<h3>Technology to keep everyone updated</h3>
								<p className="primary">
									Each one of our carers is trained in house by our care support
									team.
								</p>
							</div>
						</div>
						<button className="btn-primary">
							Learn more about our process
						</button>
					</section>

					{/*   Trust pilot embed goes here  */}
					<section>
						<div className="trust pilot"> </div>
					</section>

					<section>
						<div className="tp-divider dementia">
							<div className="tp-left">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cqc-approved.svg" />
								<p className="secondary">
									Edyn Care is approved and regulated by the Care Quality
									commission
								</p>
							</div>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thegaurdian.png"
								alt="the Gaurdian"
								objectFit="contain"
							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thetimes.png"
								alt="the times"
								objectFit="contain"
							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/theeveningstandard.png"
								alt="evening standard"
								objectFit="contain"
							/>
						</div>
					</section>
				</main>
			</div>
			<Footer />
		</>
	);
}
